import React, { Component } from 'react'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import CopyToClipboard from 'react-copy-to-clipboard';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const mapStateToProps = state => {
    return {
        data: state.data.filtered
    }
}

class FilterTable extends Component {

    constructor(props){
        super(props)
    }

    render() {

        const { SearchBar } = Search;
        
        const categories = {
            AAA:"AAA",
            AppCache:"AppCache",
            AppFW:"AppFW",
            BOOT:"BOOT",
            CLUSTER:"CLUSTER",
            COMMON:"COMMON",
            COUNTERS:"COUNTERS",
            HA:"HA",
            HTTP:"HTTP",
            LB:"LB",
            LICENSE:"LICENSE",
            MBF:"MBF",
            NETWORK:"NETWORK",
            NTP:"NTP",
            PERFORM:"PERFORM",
            POLICY:"POLICY",
            SSL:"SSL",
            TCP:"TCP",
            VPN:"VPN"
        };

        const datatypes = {
            CONFIG:"CONFIG",
            DISTRIB:"DISTRIB",
            INFO:"INFO",
            Log:"LOG",
            'Real Time':"Real Time",
            SECURITY:"SECURITY",
            STATS:"STATS",
            SYSTEM:"SYSTEM"
            
        }

        function copyFormatter(cell,row){
            return (
                <span>
                <CopyToClipboard text={cell}>
                  <button style={{marginRight:5}} className="c-button">&#916;</button>
                </CopyToClipboard>{cell}
                </span>
            )
        }

        const columns = [{
            dataField: 'CATEGORY',
            text: 'Category',
            filter: selectFilter({options: categories})
          }, {
            dataField: 'TYPE',
            text: 'Type',
            filter: selectFilter({options:datatypes})
          }, {
            dataField: 'DESCRIPTION',
            text: 'Description'
          },
          {
            dataField: 'CLI',
            text: 'CLI',
            formatter: copyFormatter
          }];

  


        return (
            <div className="FilterTable">
                <BootstrapTable striped keyField='ID' data={this.props.data} filter={filterFactory()} columns={columns} noDataIndication="No records returned"/>
            </div>
        )
    }

}

export default connect(mapStateToProps)(FilterTable);

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateSearch } from '../../actions/updateSearch';

function mapDispatchToProps(dispatch){
    return bindActionCreators({updateSearch},dispatch);
  }
  
  const mapStateToProps = state => {
    return {
      search: state.search
    }
  }

class SearchControl extends Component {
    constructor(props){
        super(props)
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event){
        this.props.updateSearch(event.target.value);
    }

    render() {
        return (
            <div className="searchcontrol">
                <input class="form-control" id="myInput" type="text" placeholder="Search.." value={this.props.search.text} onChange={this.handleChange} />
            </div>
        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(SearchControl);

import React, { Component } from 'react';
import './App.css';
import Header from './components/header';
import SearchControl from './components/searchcontrol';
import FilterTable from './components/filtertable';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { addConfig } from './actions/addConfig'

function mapDispatchToProps(dispatch){
  return bindActionCreators({addConfig}, dispatch);
}

const mapStateToProps = state => {
  return {
    data: state.data,
    search: state.search
  }
}
const axios = require('axios');

class App extends Component {
  constructor(props){
    super(props);
  }
  componentDidMount(){
    var _this = this;
    //this.serverRequest = axios.get(process.env.REACT_APP_COMMON_URL + "/config/input.json")
    this.serverRequest = axios.get("https://cli.xconfig.io/config/input.json")
    .then(function(result){
      _this.props.addConfig(result.data);
    });


  }


  render() {
    return (
      <div className="container-fluid">
        <Header />
        <SearchControl value={this.props.search} />
        <br />
        <FilterTable />
      </div>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(App);

import { UPDATE_SEARCH } from '../../actions/updateSearch'

const initialState = {
    text : ""
}
export default (state = initialState, action) => {
    switch (action.type){


    case UPDATE_SEARCH:{
        return {...state, 
            text : action.payload,
         }
    }
    
    default: 
        return state
    }
}
import React, { Component } from 'react'

class Header extends Component {


    render() {
        return (
            <React.Fragment>
            <div className="header">
                  <h2>Citrix ADC CLI Cheat Sheet</h2>
            </div>
            <br />
            <img src="nsconmsg.png" alt="COMMON"></img>
            <br /><br />
            </React.Fragment>
        )
    }

}

export default Header;

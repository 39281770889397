import { ADD_CONFIG } from '../../actions/addConfig'
import { UPDATE_SEARCH } from '../../actions/updateSearch'

export default (state, action) => {
    switch (action.type){


    case ADD_CONFIG:{
        return {...state, 
            config : action.payload,
            filtered : action.payload
         }
    }

    case UPDATE_SEARCH:{
        let rows = state.config;
        let filtered = rows.filter(function(row){ 
            let fullRow = row.DESCRIPTION + " " + row.CLI;
            return fullRow.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
            })
        return {
            ...state,
            filtered : filtered
        }
    }

    
    default: 
        return state
    }
}